<!--
 * @Description:订单申诉 selfOrderComplain
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-04 08:56:10
 * @LastEditors: houjinduo
-->
<template>
  <div class='body'>
    <van-nav-bar title="订单申诉"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-form @submit="onSubmit"
              class="main">
      <van-row class="style">订单编号</van-row>
      <van-row class="style">{{routeList.orderSequence}}</van-row>
      <van-row class="style">手机号</van-row>
      <van-row class="contactWay-input">
        <van-cell-group>
          <van-field v-model="form.appealPhone"
                     :readonly="isExist"
                     placeholder="请输入手机号" />
        </van-cell-group>
      </van-row>
      <van-row class="style">申诉类型</van-row>
      <van-field v-if="isExist"
                 :value="complaintTypeList[form.complaintType]"
                 :readonly="isExist" />
      <van-field v-else
                 readonly
                 clickable
                 name="picker"
                 :model="form.complaintType"
                 :value="value"
                 placeholder="请选择"
                 @click="showPicker = true" />
      <van-popup v-model="showPicker"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="complaintTypeList"
                    @confirm="onConfirm"
                    @cancel="showPicker = false" />
      </van-popup>

      <van-row class="style">申诉描述</van-row>
      <van-row class="contactWay-input">
        <van-cell-group>
          <van-field v-model="form.complaintDesc"
                     :readonly="isExist"
                     type="textarea"
                     style="height:140px"
                     placeholder="请详细说明申诉内容，处理将会更快捷~" />
        </van-cell-group>
      </van-row>
      <van-row class="style">申诉结果我们将以短信或消息推送的形式发送给您请关注消息管理功能</van-row>
      <van-button class="button"
                  block
                  :disabled="isExist"
                  type="info"
                  native-type="submit">{{isExist? '已 申 诉':'提 交'}}</van-button>
    </van-form>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { Toast, Dialog } from 'vant'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      routeList: {}, // 路由参数
      isExist: false, // 申诉记录是否存在
      form: {}, // 上传表单
      complaintTypeList: [], // 申诉类型列表
      value: '', // 反馈类型
      showPicker: false, // 选择弹出框
      moneyPay: ''// 实收金额
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    if (this.$route.query.paymentSequence) {
      this.value = '费用有误'
      this.form.complaintType = 0
    }
    this.routeList = this.$route.query
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getComplaint()
    this.getComplaintState()
  },
  // 方法集合
  methods: {
    // 表单提交
    onSubmit () {
      // 如果支付金额未传默认为0
      if (this.routeList.moneyPay === undefined) {
        this.moneyPay = 0
      } else {
        this.moneyPay = this.routeList.moneyPay
      }
      if (!this.form.appealPhone || this.form.appealPhone.length !== 11) {
        Toast('请输入正确的手机号')
      } else if (this.form.complaintType === undefined) {
        Toast('请选择申诉类型')
      } else if (!this.form.complaintDesc) {
        Toast('请填写描述信息')
      } else {
        Dialog.confirm({
          message: '确认提交'
        }).then(() => {
          const that = this
          const info = {
            orderId: that.routeList.orderSequence, // 订单编号
            appealReason: that.form.complaintDesc, // 申请理由
            appealType: that.form.complaintType, // 申请类型
            appealPhone: that.form.appealPhone, // 手机号
            paymentSequence: that.$route.query.paymentSequence
          }
          that.$orderComplain.appeal(info).then(() => {
            Toast('申诉提交成功')
            that.$router.go(-1)
          })
        })
      }
    },
    // 查看是否申诉
    getComplaint () {
      const info = {
        orderSequence: this.routeList.orderSequence,
        pageNum: 1,
        pageSize: 15
      }
      this.$orderComplain.queryComplaint(info).then(res => {
        // 已经申诉 无法再次申诉
        if (res.resultEntity.list.length !== 0) {
          this.isExist = true
          this.form = res.resultEntity.list[0]
        }
      })
    },
    // 选择框确认
    onConfirm (value, index) {
      this.value = value
      this.showPicker = false
      this.form.complaintType = index
    },
    // 获取申诉类型下拉框
    getComplaintState () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          { colName: 'parent_id', value: 'F8898BDD4BCF4W12ABA91DBUIUIUIU' }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        res.resultEntity.forEach(element => {
          this.complaintTypeList.push(element.name)
        })
      })
    },
    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: PingFang-SC;
  .main {
    background-color: #fff;
    height: calc(100% - 64px - 20px);
    width: 100%;
  }
}
.style {
  width: 92%;
  padding: 12px 4% 0 4%;
  height: 30px;
  line-height: 30px;
}
//描述
/deep/ textarea {
  height: 120px !important;
}
.button {
  width: 93.6%;
  margin: 30px 3.2% 0;
  border-radius: 5px;
}
</style>
